import "../styles.css";
import { genericTheme } from "@onlinedoctor-ch/docustyle";
import * as Sentry from "@sentry/node";
import { Auth0Provider } from "@auth0/auth0-react";
import Router from "next/router";
import type { AppProps } from "next/app";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { IntlProvider } from "react-intl";
import flatten from "flat";
import English from "../lang/en.json";
import { Montserrat } from "next/font/google";

const montserrat = Montserrat({
  weight: ["400", "600"],
  style: ["normal", "italic"],
  subsets: ["latin"],
});

if (process.env.enableSentry && process.env.environment !== "local") {
  Sentry.init({
    dsn: process.env.sentryDsn,
    environment: process.env.environment,
  });
}

type ManagementUiAppProps = {
  error: Error;
} & AppProps;

function ManagementUiApp({
  Component,
  pageProps,
  error,
}: ManagementUiAppProps): JSX.Element {
  // Workaround for https://github.com/zeit/next.js/issues/8592
  const modifiedPageProps = { ...pageProps, error };
  const redirectURI = process.env.hostName
    ? `${process.env.hostName}/authorize`
    : "";

  const theme = extendTheme(genericTheme, {
    fonts: {
      heading: montserrat.style.fontFamily,
      body: montserrat.style.fontFamily,
    },
  });

  return (
    <IntlProvider locale={"en"} messages={flatten(English)}>
      <ChakraProvider theme={theme}>
        <Auth0Provider
          domain={process.env.auth0Domain ?? ""}
          clientId={process.env.auth0ClientId ?? ""}
          onRedirectCallback={(appState) => {
            Router.push(appState?.returnTo || "");
          }}
          authorizationParams={{
            redirect_uri: redirectURI,
            audience: process.env.auth0Audience,
            scope: "openid profile email",
          }}
        >
          <Component {...modifiedPageProps} />
        </Auth0Provider>
      </ChakraProvider>
    </IntlProvider>
  );
}

export default ManagementUiApp;
